const services = [
  {
    name: 'Consultoria de Comunicação',
    description:
      'Apoio e orientação estratégica: objectivos, identidade, posicionamento, tom de voz, canais de comunicação, tipologia de conteúdos. Formação.',
  },
  {
    name: 'Assessoria de Comunicação',
    description:
      'Planos de Comunicação Online e Offline, Assessoria de Imprensa, Gestão de Redes Sociais.',
  },
  {
    name: 'Produção de Conteúdos',
    description: 'Texto, Vídeo, Fotografia, Multimédia.',
  },
  {
    name: 'Gestão e Produção',
    description:
      'Gestão de projectos de comunicação, Produção Cultural, Activação Cultural.',
  },
  {
    name: 'Design',
    description: 'Design Editorial, Gráfico, Digital, Web.',
  },
  {
    name: 'Branding',
    description:
      'Identidade visual e conceptual, Logotipo e Assinatura, Manual de Identidade.',
  },
];

export default services;
